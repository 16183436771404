import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { delay } from "lodash";

import { CookieStorage } from "src/common/constants/storage";

const readCookie = () =>
  (Cookies.get(CookieStorage.GACookieClientID) || "")
    .split(".")
    .slice(-2)
    .join(".");

interface UseGetGaClientID {
  isGaReady: boolean;
  gaClientID: string;
}

/**
 * Solves a race condition on components that consume the user's
 * GA id as the userid (e.g. Optimizely)
 * If the cookie doesn't exist yet, we wait for the GA cookie and
 * then return the id.
 */
export const useGetGaClientID = (): UseGetGaClientID => {
  const [isGaReady, setIsGaReady] = useState(false);
  const [gaClientID, setGaClientID] = useState("");

  useEffect(() => {
    // if the cookie already exists, return it without delay
    const cookie = readCookie();
    if (cookie && cookie !== "") {
      setGaClientID(cookie);
      setIsGaReady(true);
      return;
    }

    // if the cookie is not there yet, wait 50ms and try again in case
    // Google Tag Manager is still setting the cookie. If by then there's
    // no cookie, it's likely we're dealing with a browser blocking trackers
    delay(() => {
      const deferedCookie = readCookie();
      setGaClientID(deferedCookie);
      setIsGaReady(true);
      return;
    }, 50);
  }, []);

  return {
    isGaReady,
    gaClientID,
  };
};
