import { useRef, ReactNode, useEffect } from "react";
import {
  createInstance,
  OptimizelyProvider,
  setLogLevel,
} from "@optimizely/react-sdk";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { enums } from "@optimizely/optimizely-sdk";
import { useOrganisationSelf } from "@gocardless/api/dashboard/organisation";

import { useSegment } from "../segment/useSegment";

import { GlobalExperimentDecisions } from "./GlobalExperimentsContext";
import {
  OptimizelyAttributes,
  useOptimizelyAttributes,
} from "./useOptimizelyAttributes";
import { useGetGaClientID } from "./utils/useGetGaClientId";

import { getConfig, Environment, isEnvironment } from "src/common/config";
import { cookieDomain, CookieStorage } from "src/common/constants/storage";
import { getItem } from "src/common/local-storage/local-storage";
import { TrackingEvent } from "src/common/trackingEvents";
import { CountryCodeSupported } from "src/common/country";
import { useAccessToken } from "src/common/authorisation";

const DATAFILE_URL = `${getConfig().client.urls.api}/flags`;

const randomId = uuidv4();

const optimizely = createInstance({
  sdkKey: getConfig().client.optimizely.sdkKey,
  datafileOptions: {
    urlTemplate: DATAFILE_URL,
  },
});

if (isEnvironment(Environment.Production)) {
  setLogLevel(enums.LOG_LEVEL.WARNING);
}

interface OptimizelyProps {
  children: ReactNode;
  userCountry?: CountryCodeSupported;
}

interface TargetedOptimizelyProps extends OptimizelyProps {
  optimizelyID: string;
  attributes?: Partial<OptimizelyAttributes>;
}

const TargetedOptimizely = ({
  optimizelyID,
  children,
  attributes,
}: TargetedOptimizelyProps) => (
  <OptimizelyProvider
    optimizely={optimizely}
    user={{
      id: optimizelyID,
      attributes: attributes ?? {},
    }}
  >
    <GlobalExperimentDecisions />
    {children}
  </OptimizelyProvider>
);

export const UnauthenticatedOptimizely = ({
  children,
  userCountry,
}: OptimizelyProps) => {
  /**
   * We check to see if we know the unauthenticated OptimizelyID used on the website (or other GC web products)
   * If not we create one and make it accessible across all .gocardless domain
   */
  const environment = getConfig().shared?.environment;
  const optimizelyIDCookieDomain = cookieDomain[environment];

  const unauthenticatedOptimizelyID = Cookies.get(
    CookieStorage.PreSignupOptimizelyID
  );

  const { isGaReady, gaClientID } = useGetGaClientID();
  const unauthenticatedIDRef = useRef(unauthenticatedOptimizelyID || randomId);

  useEffect(() => {
    if (unauthenticatedOptimizelyID) return;

    const cookieOptions = optimizelyIDCookieDomain
      ? { domain: optimizelyIDCookieDomain }
      : undefined;
    Cookies.set(CookieStorage.PreSignupOptimizelyID, randomId, cookieOptions);
    unauthenticatedIDRef.current = randomId;
  }, [unauthenticatedOptimizelyID, optimizelyIDCookieDomain]);

  const cypressFeatureFlag = getItem("gc.cypress");

  return isGaReady ? (
    <TargetedOptimizely
      optimizelyID={gaClientID}
      attributes={{
        cypress: cypressFeatureFlag,
        anonymous_client_id: unauthenticatedIDRef.current,
        ga_client_id: gaClientID,
        country: userCountry?.toString(),
      }}
    >
      {children}
    </TargetedOptimizely>
  ) : null;
};

type DecisionType = "feature-test" | "flag";

// Optimizely does not provide a full type for the notification data provided as
// a payload when calling notification listeners. This interface should not be
// treated as exhaustive (there are other properties which can be returned in
// decisionInfo). It would also be advisable to code defensively here to ensure
// that we don't throw "cannot read property X of undefined" type errors if the
// properties change or are not correct.
interface NotificationData {
  type: DecisionType;
  userId: string;
  attributes: { [key: string]: unknown };
  decisionInfo?: {
    flagKey?: string;
    variationKey?: string;
    enabled?: boolean;
    decisionEventDispatched?: boolean;
    ruleKey?: string;
    experimentKey?: string;
  };
}

// A cache for the experiment for which we have sent decisions to Segment
// already. We don't want to send more than one decision event per session to
// avoid sending too many events, so we use this cache as a check.
// This is outside of the component as we don't want ever want updates to the
// set to cause a rerender of the component tree.
const FIRED_EVENTS = new Set<string>();

export const Optimizely = ({ children }: OptimizelyProps) => {
  const [accessToken] = useAccessToken();
  const { data, isLoading } = useOrganisationSelf(
    accessToken?.links?.organisation || null
  );

  const organisation = data?.organisations;
  const { sendEvent } = useSegment();

  const attributes = useOptimizelyAttributes();

  // Every time we send a decision event to Optimizely for the first time in a
  // session for an experiment, also send an event to Segment. This allows us to
  // easily create experiment cohorts in Amplitude which makes our analysis a
  // lot more easy and accurate.
  useEffect(() => {
    const listener =
      optimizely.notificationCenter.addNotificationListener<NotificationData>(
        enums.NOTIFICATION_TYPES.DECISION,
        ({ decisionInfo, type }) => {
          // We have to check here whether the decision is for a feature-test or
          // a flag as a decision event can come from either depending on how
          // the decision is being made, and the decisionInfo payloads differ
          // between the two types.
          if (type === "feature-test") {
            if (
              decisionInfo?.experimentKey &&
              decisionInfo?.variationKey &&
              !FIRED_EVENTS.has(decisionInfo?.experimentKey)
            ) {
              triggerExperimentSegmentEvent(
                decisionInfo.experimentKey,
                decisionInfo.variationKey
              );
            }
          } else if (type === "flag") {
            if (
              decisionInfo?.ruleKey &&
              decisionInfo?.variationKey &&
              decisionInfo?.decisionEventDispatched &&
              !FIRED_EVENTS.has(decisionInfo.ruleKey)
            ) {
              triggerExperimentSegmentEvent(
                decisionInfo.ruleKey,
                decisionInfo.variationKey
              );
            }
          }
        }
      );
    return () => {
      optimizely.notificationCenter.removeNotificationListener(listener);
    };
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerExperimentSegmentEvent = (
    experimentName: string,
    variationName: string
  ) => {
    sendEvent(TrackingEvent.EXPERIMENTS_DECISION_CREATED, {
      experiment_name: experimentName,
      variation_name: variationName,
    });
    FIRED_EVENTS.add(experimentName);
  };

  // explicit checking of attribute being null here
  if (!isLoading && (attributes === null || !organisation?.id)) {
    return (
      <OptimizelyProvider optimizely={optimizely}>
        {children}
      </OptimizelyProvider>
    );
  }

  if (attributes && organisation?.id) {
    return (
      <TargetedOptimizely
        optimizelyID={organisation.id}
        attributes={attributes}
      >
        {children}
      </TargetedOptimizely>
    );
  }

  // likely still loading the organisation or attributes
  return null;
};
